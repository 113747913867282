const postAjax = (url, data, success) => {
  $.ajax({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    url: url,
    method: 'POST',
    data: data,
  })
    .done(function (response) {
      success(response);
    })
    .fail(function (error) {
      console.log('ajax error: ' + error.statusText);
    });
}

window.commonFunction = {};
window.commonFunction.postAjax = postAjax;
